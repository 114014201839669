import React from 'react';
import { Query } from 'react-apollo';
import GraphCMSContent from '../services/graphcms';
import SinglePostPreview from '../components/singlePostPreview'
import { NavLink } from 'react-router-dom';
// import gql from "graphql-tag";
// import { graphql } from 'graphql';

const PostSingleView = props => {
    const post_slug = props.match.params.slug;
    const [posts, setPosts] = React.useState([]);
    const Client = new GraphCMSContent();

    const LoadingPostsJsx = () => (
        <div>
            Loading...
        </div>
    );

    const ErrorLoadingPostsJsx = () => (
        <div className="mx-auto alert-danger">
            Error!
        </div>
    );

    return (
        <section>
            <div className="install_getting">
                <div className="sngl_guide">
		            <div className="container">
                        <div className="sub_sngl_guide">
                            <NavLink to="/">
                                <p>Guides</p>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Query query={Client.fetchSinglepost({post_slug})}>
                        {
                            ({loading, error, data}) => {
                                if (loading) return LoadingPostsJsx();
                                if (error) {
                                    console.log(error);
                                    return ErrorLoadingPostsJsx();
                                }
                                return <SinglePostPreview post={data} />
                            }
                        }
                    </Query>
                </div>
            </div>
        </section>
    );
};

export default PostSingleView;