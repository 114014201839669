import gql from 'graphql-tag';

export const GET_TECH = gql`
    query {
        __type(name: "Tech") {
            enumValues {
            name
            isChecked: isDeprecated
            }
        }
    }
`;