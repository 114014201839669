// import { gql } from "@apollo/client";
import gql from 'graphql-tag';

export const GET_POSTS = gql`
  query posts($first: Int, $after: String, $search: String, $selectedTech: [Tech!]){
    postsConnection(orderBy: id_DESC, first: $first, after: $after, where: {_search: $search, tech_contains_some: $selectedTech}) {
      pageInfo {
          endCursor
          hasNextPage
          pageSize
      }
      edges {
          cursor
          node {
          title
          slug
          trending
          id
          category
          coverImage {
              url
              id
              handle
          }
          createdAt
          body {
              html
          }
          }
      }
    }
  }
`;