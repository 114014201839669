import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/logo.png';
const Navbar = props => (
    <section>
        <div className="main_header">
            <div className="container">
                <div className="sub_header">
                    <div className="header_left">
                        <NavLink to="/">
                            <img className='logo' src={logo} alt="Logo"/>
                        </NavLink>
                    </div>
                    <div className="header_center">
                        <ul>
                            <li><NavLink to="/">Docs</NavLink></li>
                            <li><NavLink to="/">Pricing</NavLink></li>
                            <li><NavLink to="/">for NFTs</NavLink></li>
                            <li><NavLink to="/">Careers</NavLink></li>
                            <li><NavLink to="/">Contact</NavLink></li>
                        </ul>
                    </div>
                    <div className="header_right">
                        <div className="header_rght_li">
                            <ul>
                                <li><NavLink to="/">Login</NavLink></li>
                            </ul>
                        </div>
                        <div className="header_btn">
                            <button>Get Started</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    // <div className="d-flex flex-row justify-content-between pt-4 pb-2" id="navbar">
    //     <div className="ml-4 brand-icon p-2">
    //         German Times
    //     </div>
    //     <div className="mr-4" style={{ width: '20%' }}>
    //         <ul className="list-unstyled d-flex flex-row justify-content-between">
    //             <li>
    //                 <NavLink to="/">Home</NavLink>
    //             </li>
    //             {/* <li>
    //                 <NavLink to="/dashboard">Dashboard</NavLink>
    //             </li> */}
    //             <li>
    //                 <NavLink to="/about">About</NavLink>
    //             </li>
    //         </ul>
    //     </div>
    // </div>
);

export default Navbar;