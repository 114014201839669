import gql from 'graphql-tag';
import { GraphQLClient } from 'graphql-request';
import dotenv from 'dotenv';
// import { lexicographicSortSchema } from 'graphql';


export default class GraphCMSContent {
    constructor() {
        dotenv.config();
        
        this.Client = new GraphQLClient(
            process.env.REACT_APP_GCMS_URL,
            {
                headers: {
                    authorization: `Bearer ${process.env.REACT_APP_GCMS_AUTH}`
                }
            }
        );
    }

    fetchPosts(props) {
        const QUERY = gql`
            query {
                posts(where: {_search: "${props.search}"}) {
                    id
                    title
                    createdAt
                    slug
                    excerpt
                    category
                    trending
                    coverImage {
                        id
                        url
                    }
                }
            }
        `;
        return QUERY;
    };

    fetchTech() {
        const QUERY = gql`
            query {
                __type(name: "Tech") {
                    enumValues {
                    name
                    }
                }
            }
        `;
        return QUERY;
    };
    
    fetchSinglepost(slug) {
        const QUERY = gql`
        query {
            post(where: {slug: "${slug.post_slug}"}) {
                id
                title
                createdAt
                body {
                    raw
                    html
                    text
                }
                category
                trending
                slug
                npmLink
                coverImage {
                    id
                    url
                }
            }
          }
          
        `;
        return QUERY;
    };

    async createPost({title, body}) {
        const QUERY = gql`
            mutation {
                createPost(data: {
                    title: "${title}",
                    body: "${body}"
                }) {
                    id
                    title
                    body
                }
            }
        `;
        
        try {
            const data = await this.Client.request(QUERY);
            return data;
        } catch(error) {
            console.log("Error at createPost:>>", error);
            return false;
        }
    }

    async updatePost(post_id, { title, body }) {
        const QUERY = gql`
            mutation {
                updatePost(
                    where: { id: "${post_id}" }
                    data: { title: "${title}", body: "${body}" }
                ) {
                    id
                    title
                    body
                }
            }
        `;

        try {
            const data = await this.Client.request(QUERY);
            return data;
        } catch(error) {
            console.log("Error at updatePost:>>", error);
            return false;
        }
    }
}