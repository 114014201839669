import React, { useEffect } from "react";
import Prism from 'prismjs';
import "prismjs/themes/prism-okaidia.css";
import moment from 'moment';

const SinglePostPreview = (post) => {
    useEffect(() => {
        Prism.highlightAll();
      }, []);
    return (
        <div className="sub_install_getting">
            <div className="left_sec">
                <div className="magicsdk">
                    <p>{post.post.post.category}</p>
                    <h1>{post.post.post.title}</h1>
                    <p className="dt">{moment(post.post.post.createdAt).format("Do MMMM YYYY")}</p>
                    <div className="code_block top_code">
                        <h4>Download this example and get started in seconds:</h4>
                        <pre><code>{post.post.post.npmLink}</code></pre>
                    </div>
                    <img className="cover-image" src={ (() => post.post.post.coverImage !== null ? post.post.post.coverImage.url : 'https://media-exp1.licdn.com/dms/image/C4E1BAQErE1VCVPWvUQ/company-background_10000/0/1560357743378?e=1617372000&v=beta&t=N_Bp7Pp4kDYPRMlx1B47pTJ32h2QoRxlt-Csu39pzEA')() } alt="" />
                </div>
                    {post.post.post.body ? 
                        <div className="main_content">
                            {post.post.post.body.html ? 
                                <div className="sub_content">
                                <div dangerouslySetInnerHTML={{__html: post.post.post.body.html}}></div>                     
                            </div>
                            : ''}
                        </div>
                    : null }
            </div>
            {/* <div className="right_sec">
                <div className="rght_sub_sec stic">
                    <h5>{post.post.post.title}</h5>
                    <ul><a href="#get-started">Get Started</a>
                        <li><a href="#0-get-the-template-code">0. Get the template code</a></li>
                        <li><a href="#1-install-magic-sdk">1. Install Magic SDK</a></li>
                        <li><a href="#2-initialize-magic-instance">2. Initialize Magic Instance</a></li>
                        <li><a href="#3-implement-render-function">3. Implement Render Function</a></li>
                        <li><a href="#4-implement-login-handler">4. Implement Login Handler</a></li>
                        <li><a href="#5-implement-logout-handler">5. Implement Logout Handler</a></li>
                        <li><a href="#6-done">6. Done</a></li>
                    </ul>
                    <ul><a href="#whats-next">What's next?</a>
                        <li><a href="#use-magic-with-existing-tools">Use Magic with existing tools</a></li>
                        <li><a href="#customize-your-magic-flow">Customize your Magic flow</a></li>
                    </ul>
                </div>
            </div> */}
        </div>
    );
};

export default SinglePostPreview;