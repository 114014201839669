import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import LazyImage from 'react-lazy-blur-image';

const PostPreview = ({post}) => {
    const isTrending = post.node.trending;
    return (
        <Link to={`/post/${post.node.slug}`}>
            <div className="guide_con">
                {/* <img className="cover-image" src={ (() => post.node.coverImage !== null ? post.node.coverImage.url : '')() } alt="" /> */}
                <div className='guide_img'>
                    <LazyImage
                        placeholder={"https://media.graphcms.com/resize=fit:clip,height:10,width:10/" + post.node.coverImage.handle}
                        uri ={post.node.coverImage.url}
                        render={(src, style) => <img src={src} style={style} />}
                    />
                </div>
                <p className="sub_title">{post.node.category}</p>
                <h2 className="title">{post.node.title}</h2>
                <p className="descp">{post.node.excerpt}</p>
                <p className="post_date">{moment(post.node.createdAt).format("MM/D/YYYY")}</p>
                {isTrending ? <div className='star_img'></div> : ''}
            </div>
        </Link>
    );
};

export default PostPreview;