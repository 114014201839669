import React, { useState, useEffect } from "react";
import { useQuery } from "react-apollo";
import { GET_POSTS } from "../services/getPosts";
import { GET_TECH } from "../services/getTech";
import PostsPreview from "../components/postPreview";
import Popup from "reactjs-popup";
import GraphCMSContent from '../services/graphcms';
import "reactjs-popup/dist/index.css";
import LazyImage from 'react-lazy-blur-image';
import placeholder from '../assets/images/placeholder.gif'

const first = 3;
const delay = true;

const Home = React.memo(() => {
  const Client = new GraphCMSContent();
  const [search, setSearch] = useState(" ");
  const defaultTech = [
    {
      enumValues: [
          {
            __typename: "__EnumValue",
            isChecked: false,
            name: "Javascript"
          },
      ]
    },
  ];
  const [techstack, setTechstack] = useState(defaultTech[0]);
  const [selectedTech, setSelectedTech] = useState(['All']);
  const { error: errorT, data: dataT, loading: loadingT, networkStatus: networkStatusT } = useQuery(
    GET_TECH,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => setTechstack(data.__type)
    }
  );
  const { error, data, loading, fetchMore, networkStatus } = useQuery(
    GET_POSTS,
    {
      variables: { first, delay, search, selectedTech },
      notifyOnNetworkStatusChange: true,
    });

  if (error) {
    console.log(error.message);
    return <div>An error occurred</div>;
  }
  if (errorT) {
    console.log(error.message);
    return <div>An error occurred</div>;
  }
  if (networkStatus === 1) {
    return <div>Loading...</div>;
  }
  if (networkStatusT === 1) {
    return <div>Loading...</div>;
  }
  
  let typingTimer = null;
  
  const techChecked = (event) => {
    const clickedName = event.target.value;    
    if(event.target.checked == true) {
      setTechstack(prev => ({
        enumValues: prev.enumValues.map(techstack => techstack.name === clickedName ? { ...techstack, isChecked: true} : techstack)
      }));
      if(selectedTech.length == 1 && selectedTech[0] == 'All') {
        const newSelectedTech = [...selectedTech];
        const id = selectedTech.indexOf('All');
        newSelectedTech.splice(id, 1);
        setSelectedTech(newSelectedTech);
      }
      setSelectedTech(prev => [...prev, clickedName]);
    } else if(event.target.checked == false) {
        setTechstack(prev => ({
          enumValues: prev.enumValues.map(techstack => techstack.name === clickedName ? { ...techstack, isChecked: false} : techstack)
        }));
        const indexid = selectedTech.indexOf(clickedName);
        const id = selectedTech.indexOf(clickedName);
        const newSelectedTech = [...selectedTech];
        newSelectedTech.splice(indexid, 1);
        if(newSelectedTech == '') {
          setSelectedTech(['All']);
        } else {
          setSelectedTech(newSelectedTech);
        }
    }
  }

  const clearSelected = () => {
    setTechstack(prev => ({
      enumValues: prev.enumValues.map(techstack => techstack.isChecked === true ? { ...techstack, isChecked: false} : techstack)
    }))
    setSelectedTech(['All']);
  }

  const hasNextPage = data.postsConnection.pageInfo.hasNextPage;
  const isRefetching = networkStatus === 3;
  return (
    <div>
      <section>
        <div className="main_guides">
          <div className="container">
            <div className="sub_guides">
              <h1>Guides</h1>
              <p>A collection of 35 posts</p>
            </div>
            <div className="search">
              <div className="srch_input">
                <div className="search_icon">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search..."
                    onChange={(event) => {
                      const val = event.target.value;
                      clearTimeout(typingTimer);
                      typingTimer = setTimeout(() => {
                          setSearch(val);
                          fetchMore({
                            variables: {
                              first: first,
                              // after: data.postsConnection.pageInfo.endCursor,
                              delay,
                              search: val,
                              selectedTech: selectedTech
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                              if (!fetchMoreResult) return prev;
                              return fetchMoreResult;
                            },
                          });
                      }, 1000);
                    }}
                  />
                </div>
                {/* <input type="submit" value="Filter By Tech" /> */}
                <Popup trigger={<button className="button"> Filter by Tech {selectedTech[0] == 'All' ? null : selectedTech.length }</button>} modal nested >
                  {(close) => (
                    <div className="modal">
                        <div className="popup_header">
                            <div className="header_title"> Filter by Tech </div>
                            <button className="close" onClick={close}>&times;</button>
                        </div>
                        <div className="popup_content">
                            <div className="content">
                              <ul>
                              {
                                techstack.enumValues.map((tech) => (
                                  <li class={tech.name}>
                                    <input checked={tech.isChecked} type="checkbox" tabindex="0" name="tech-selected" value={tech.name} id={tech.name} onChange={(event)=> techChecked(event) }></input>
                                    {/* <img className="tech_img" src={process.env.PUBLIC_URL + "/images/" + tech.name + ".webp"} alt="" /> */}
                                    <label htmlFor={tech.name}>
                                      <LazyImage
                                        placeholder={placeholder}
                                        uri ={process.env.PUBLIC_URL + "/images/" + tech.name + ".webp"}
                                        render={(src, style) => <img src={src} style={style} />}
                                      />
                                      {tech.name.replace(/_/g," ")}
                                    </label>
                                  </li>
                                ))
                              }
                              </ul>
                            </div>
                        </div>
                        <div className="popup_footer">
                              <button className="clear_all" onClick={() => { clearSelected(); }}>Clear All</button>
                              <button className="save_btn" onClick={() => { close(); }}>
                                  Save
                              </button>
                          </div>
                    </div>
                  )}
                </Popup>
              </div>
              <div className="filter_btn"></div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="scnd_section">
          <div className="container">
            <div className="main_div">
              {
                data.postsConnection.edges.length == 0 ? 'No Posts found!' :
                  data.postsConnection.edges.map((edge) => (
                    <PostsPreview post={edge} />
                  ))
              }
              {hasNextPage && (
                <button
                  className="load_more"
                  id="buttonLoadMore"
                  disabled={isRefetching}
                  loading={isRefetching}
                  onClick={() => {
                    fetchMore({
                      variables: {
                        first: data.postsConnection.pageInfo.pageSize,
                        after: data.postsConnection.pageInfo.endCursor,
                        delay,
                        search: search,
                        selectedTech: selectedTech
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        const previousEdges = prev.postsConnection.edges;
                        const fetchMoreEdges =
                          fetchMoreResult.postsConnection.edges;
                        fetchMoreResult.postsConnection.edges = [
                          ...previousEdges,
                          ...fetchMoreEdges,
                        ];
                        return { ...fetchMoreResult };
                      },
                    });
                  }}
                >
                  <div className="learn_more_label">Load more</div>
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});
export default Home;
